import { ClientContentType, Formats, } from '../client-models';
import { APIPath, QueryParams } from '../constants';
import { composeDownloadUrl, stringifyQuery } from '../functions';
/** Client for v0/users APIs */
export class UsersClient {
    constructor(client) {
        this.client = client;
    }
    query(query, options) {
        return this.client.get(this.url(), { ...options, query: stringifyQuery(query) });
    }
    get(id, token, options) {
        return this.client.get(this.url(id), { ...options, params: { token }, anonymous: true });
    }
    getByUsername(username, options) {
        return this.client.get(this.url(APIPath.UsersUsername, username), options);
    }
    getAuthProviders(id, options) {
        return this.client.get(this.url(id, APIPath.UsersAuthProviders), options);
    }
    bulkAdd(users, options) {
        return this.client.post(this.url(APIPath.UsersBulk), { ...options, body: users });
    }
    bulkAddCsv(file, additionalData, options) {
        const formData = this.getCSVBulkData(file, additionalData);
        return this.client.post(this.url(APIPath.UsersBulk), { ...options, body: formData, multipart: true });
    }
    bulkInvite(userInvites, options) {
        return this.client.post(this.url(APIPath.UsersBulkInvite), { ...options, body: userInvites });
    }
    bulkInviteCsv(file, additionalData, options) {
        const formData = this.getCSVBulkData(file, additionalData);
        return this.client.post(this.url(APIPath.UsersBulkInvite), { ...options, body: formData, multipart: true });
    }
    save(user, options) {
        if (!user.id) {
            return this.client.post(this.url(), { ...options, body: user });
        }
        return this.client.put(this.url(user.id), user, options);
    }
    delete(id, options) {
        return this.client.delete(this.url(id), options);
    }
    resetPassword(username, options) {
        return this.client.post(this.url(APIPath.UsersUsername, username, APIPath.UsersUsernameResetPassword), { ...options, anonymous: true });
    }
    updatePassword(id, password, token, options) {
        return this.client.patch(this.url(id), { password }, { ...options, params: { token }, anonymous: true });
    }
    uploadAttachment(userId, file, options) {
        return this.client.upload(file, this.url(userId, APIPath.UsersFiles), options);
    }
    getDownloadUrl(query, format = Formats.Csv) {
        return composeDownloadUrl(this.url(), format, query);
    }
    getAttachmentUrl(userId, attachmentId) {
        var _a;
        return `${this.url(userId, APIPath.UsersFiles, attachmentId)}?${QueryParams.AccessToken}=${(_a = this.client.token) !== null && _a !== void 0 ? _a : ''}`;
    }
    url(...extra) {
        return this.client.buildUrl(APIPath.Users, ...extra);
    }
    getCSVBulkData(file, additionalData) {
        const formData = new FormData();
        formData.append(Formats.Csv, file);
        if (additionalData) {
            formData.append(Formats.Json, new Blob([JSON.stringify(additionalData)], {
                type: ClientContentType.ApplicationJson,
            }));
        }
        return formData;
    }
}
