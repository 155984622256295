import { QueryCombinatorOperators, QueryIdentifierArgsOnlyOperators, QueryOperatorOperators } from '../constants';
import { NodeType, QueryOperators } from '../models';
export const stringifyQuery = (query) => query ? query.stringify().replace(/'/g, '%27') : undefined;
/** Iterate over an AstNode tree
 *
 * @param node top node to start the iteration from
 * @param parent reference to the parent of the @param node
 * @param options options to determine the behaviour of this iterator, @see AstNodeIteratorOptions
 * @return iterable of @see AstNodeIteratorEntry entries
 */
export function* astNodeIterator(node, parent, options = { order: 'postOrder' }) {
    const dive = node.args && (!options.canDive || options.canDive(node, parent));
    const emit = !options.canEmit || options.canEmit(node, parent);
    if (options.order === 'postOrder') {
        if (dive && node.args) {
            for (const arg of node.args) {
                yield* astNodeIterator(arg, node, options);
            }
        }
        if (emit) {
            yield { node, parent };
        }
    }
    else {
        if (emit) {
            yield { node, parent };
        }
        if (dive && node.args) {
            for (const arg of node.args) {
                yield* astNodeIterator(arg, node, options);
            }
        }
    }
}
/** Merge multiple AstNode in a single one, flattening the operators when possible */
export const mergeAstNodes = (combinator, ...astNodes) => {
    var _a;
    const args = [];
    for (const astNode of astNodes) {
        if (!astNode) {
            continue;
        }
        const canFlatten = astNode.type === NodeType.Combinator && astNode.op === combinator;
        args.push(...(canFlatten ? ((_a = astNode.args) !== null && _a !== void 0 ? _a : []) : [astNode]));
    }
    if (!args.length) {
        return;
    }
    return {
        type: NodeType.Combinator,
        op: combinator,
        args,
    };
};
export const astNodeErrorsCheck = (node, allowExpressionNodeType = true) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const errors = [];
    const operatorNodeArgsTypes = [NodeType.Expression, NodeType.Identifier, NodeType.Value];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (node.type == null) {
        errors.push(`type not defined`);
    }
    if (operatorNodeArgsTypes.includes(node.type)) {
        if (node.args) {
            errors.push(`args not allowed for type ${nodeTypeString[node.type]}`);
        }
        if (node.op) {
            errors.push(`op not allowed for type ${nodeTypeString[node.type]}`);
        }
        if (!node.value) {
            errors.push(`value not defined for type ${nodeTypeString[node.type]}`);
        }
    }
    else {
        if (!node.op) {
            errors.push(`op required for type ${nodeTypeString[node.type]}`);
        }
        if (!((_a = node.args) === null || _a === void 0 ? void 0 : _a.length)) {
            errors.push(`args are required for ${nodeTypeString[node.type]}`);
        }
        if (node.value) {
            errors.push(`value not allowed for type ${nodeTypeString[node.type]}`);
        }
    }
    switch (node.type) {
        case NodeType.Combinator:
            if (node.op && !QueryCombinatorOperators.includes(node.op)) {
                errors.push(`op ${node.op} not valid for type ${nodeTypeString[node.type]}`);
            }
            if (node.op === QueryOperators.Not && ((_b = node.args) === null || _b === void 0 ? void 0 : _b.length) !== 1) {
                errors.push(`op ${node.op} require 1 arg for type ${nodeTypeString[node.type]}`);
            }
            break;
        case NodeType.Operator:
            if (node.op && !QueryOperatorOperators.includes(node.op)) {
                errors.push(`op ${node.op} not valid for type ${nodeTypeString[node.type]}`);
            }
            if (!QueryIdentifierArgsOnlyOperators.includes(node.op) && ((_c = node.args) === null || _c === void 0 ? void 0 : _c.length) !== 2) {
                errors.push(`op ${node.op} require 2 args for type  ${nodeTypeString[node.type]}`);
            }
            if ([QueryOperators.Has, QueryOperators.Hasnt].includes(node.op) && ((_d = node.args) === null || _d === void 0 ? void 0 : _d.length) !== 1) {
                errors.push(`op ${node.op} require 1 arg for type ${nodeTypeString[node.type]}`);
            }
            errors.push(...((_e = node.args) !== null && _e !== void 0 ? _e : [])
                .filter((arg) => !operatorNodeArgsTypes.includes(arg.type))
                .map((arg) => `type ${nodeTypeString[arg.type]} not allowed as arg of ${nodeTypeString[node.type]} node`));
            if (QueryIdentifierArgsOnlyOperators.includes(node.op)) {
                errors.push(...((_f = node.args) !== null && _f !== void 0 ? _f : [])
                    .filter((arg) => arg.type !== NodeType.Identifier)
                    .map((arg) => `type ${nodeTypeString[arg.type]} not allowed as arg of op ${node.op}`));
            }
            else {
                if (!((_g = node.args) === null || _g === void 0 ? void 0 : _g.find((arg) => arg.type === NodeType.Identifier))) {
                    errors.push(`op ${node.op} require an arg of type ${nodeTypeString[NodeType.Identifier]}`);
                }
                if (!((_h = node.args) === null || _h === void 0 ? void 0 : _h.find((arg) => arg.type === NodeType.Value)) && (!allowExpressionNodeType || !((_j = node.args) === null || _j === void 0 ? void 0 : _j.find((arg) => arg.type === NodeType.Expression)))) {
                    errors.push(`op ${node.op} require an arg of type ${nodeTypeString[NodeType.Value]} or ${nodeTypeString[NodeType.Expression]}`);
                }
            }
            break;
        case NodeType.Expression:
            if (!allowExpressionNodeType) {
                errors.push(`type ${nodeTypeString[node.type]} not allowed`);
            }
    }
    for (const arg of (_k = node.args) !== null && _k !== void 0 ? _k : []) {
        errors.push(...((_l = astNodeErrorsCheck(arg, allowExpressionNodeType)) !== null && _l !== void 0 ? _l : []));
    }
    return errors.length ? errors : undefined;
};
const nodeTypeString = {
    [NodeType.Combinator]: 'Combinator',
    [NodeType.Expression]: 'Expression',
    [NodeType.Identifier]: 'Identifier',
    [NodeType.Operator]: 'Operator',
    [NodeType.Value]: 'Value',
};
