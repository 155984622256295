import { QueryOperators } from '../models';
/** Operators for Combinator AstNode  */
export const QueryCombinatorOperators = [
    QueryOperators.And,
    QueryOperators.Or,
    QueryOperators.Not,
];
/** Operators for Operator AstNode */
export const QueryOperatorOperators = [
    QueryOperators.Equal,
    QueryOperators.NotEqual,
    QueryOperators.LowerThan,
    QueryOperators.LowerEqual,
    QueryOperators.GreaterThan,
    QueryOperators.GreaterEqual,
    QueryOperators.In,
    QueryOperators.Contains,
    QueryOperators.Descendants,
    QueryOperators.Has,
    QueryOperators.Hasnt,
    QueryOperators.Search,
    QueryOperators.Sort,
    QueryOperators.Offset,
    QueryOperators.Limit,
    QueryOperators.Include,
];
export const QueryComparisonOperators = [
    QueryOperators.Equal,
    QueryOperators.NotEqual,
    QueryOperators.LowerThan,
    QueryOperators.LowerEqual,
    QueryOperators.GreaterThan,
    QueryOperators.GreaterEqual,
    QueryOperators.In,
    QueryOperators.Contains,
    QueryOperators.Descendants,
];
/** Operators thas requires only args of type Identifier */
export const QueryIdentifierArgsOnlyOperators = [
    QueryOperators.Has,
    QueryOperators.Hasnt,
    QueryOperators.Include,
];
