/** InjectionToken like */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ConnectionOptions {
}
/** InjectionToken like */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ProjectContentOptions {
}
/** InjectionToken like */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class FormDataOptions {
}
