export var APIPath;
(function (APIPath) {
    APIPath["Oauth"] = "oauth";
    APIPath["Devices"] = "devices";
    APIPath["DownloadTokens"] = "download-tokens";
    APIPath["Companies"] = "companies";
    APIPath["ExternalDataSources"] = "external-data-sources";
    APIPath["FormData"] = "form-data";
    APIPath["FormDataCount"] = "_count";
    APIPath["FormDataFiles"] = "_files";
    APIPath["FormDataRevisions"] = "revisions";
    APIPath["HierarchyUnits"] = "units";
    APIPath["HierarchyChildren"] = "children";
    APIPath["Me"] = "me";
    APIPath["MePermissions"] = "permissions";
    APIPath["Settings"] = "settings";
    APIPath["SettingsAllowedFileTypes"] = "allowed-file-types";
    APIPath["Oidc"] = "oidc";
    APIPath["OidcStates"] = "states";
    APIPath["Assets"] = "assets";
    APIPath["Roles"] = "roles";
    /** @deprecated */
    APIPath["DefaultClaims"] = "default-claims";
    APIPath["UserClaims"] = "user-claims";
    APIPath["CompanyClaims"] = "company-claims";
    APIPath["Users"] = "users";
    APIPath["UsersBulk"] = "bulk";
    APIPath["UsersBulkInvite"] = "bulk-invite";
    APIPath["UsersCsv"] = "csv";
    APIPath["UsersAuthProviders"] = "auth-providers";
    APIPath["UsersFiles"] = "files";
    APIPath["UsersUsername"] = "username";
    APIPath["UsersUsernameResetPassword"] = "reset-password";
    APIPath["Projects"] = "projects";
    APIPath["Preview"] = "preview";
    APIPath["Versions"] = "versions";
    APIPath["Latest"] = "latest";
    APIPath["Artifacts"] = "artifacts";
    APIPath["Diff"] = "diff";
    APIPath["GeoRegions"] = "geo-regions";
    APIPath["BeaconRegions"] = "beacon-regions";
    APIPath["Views"] = "views";
    APIPath["ViewsDefinition"] = "definition";
    APIPath["Pages"] = "pages";
    APIPath["Collections"] = "collections";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    APIPath["CollectionsDefinition"] = "definition";
    APIPath["Buckets"] = "buckets";
    APIPath["Forms"] = "forms";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    APIPath["FormsVersions"] = "versions";
    APIPath["Tables"] = "tables";
    APIPath["Structure"] = "structure";
    APIPath["Identifiers"] = "identifiers";
})(APIPath || (APIPath = {}));
