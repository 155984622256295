import { AuthRequestWrapper } from './auth-request-wrapper';
import { ClientHeaders } from './client-headers';
import { APIPath } from './constants';
import { appendParamsToUrl } from './functions';
import { RequestManager } from './request-manager';
// TODO add once node environment established
// uncertain on the correct variable names for nfetch
// if (!(global as any).fetch) {
//     (global as any).fetch = (global as any).nfetch;
// }
// if (!(global as any).Headers) {
//     (global as any).Headers = (global as any).nodeFetch.Headers;
// }
export var GrantType;
(function (GrantType) {
    GrantType["RefreshToken"] = "refresh_token";
    GrantType["AuthorizationCode"] = "authorization_code";
    GrantType["Password"] = "password";
})(GrantType || (GrantType = {}));
// TODO Move authentication & status to AuthenticationClient separate class
export class Client {
    constructor(options, storage, interceptor) {
        this.options = options;
        this.storage = storage;
        /**
         * Token Storage values
         * Local values provide a fallback if oauth is used without token storage provided
         */
        this._token = null;
        this._expiresAt = null;
        this._refreshToken = null;
        this.headers = new ClientHeaders(this.options);
        this.authRequest = new AuthRequestWrapper(this.options, this.headers, this);
        this.request = new RequestManager(this.options, this.headers, this, this.authRequest, interceptor);
    }
    authenticate(username, password) {
        return this.oauth({ username, password });
    }
    authenticateWithCode(code, redirectUri, providerId) {
        return this.oauth({ code, redirect_uri: redirectUri, provider_id: providerId });
    }
    async registerDevice(device) {
        await this.put(this.buildUrl(APIPath.Devices, device.id), device, { anonymous: true });
        this.headers.deviceId = device.id;
    }
    getDownloadToken(url) {
        return this.post(this.buildUrl(APIPath.DownloadTokens), { body: { url } });
    }
    buildUrl(...parts) {
        parts = parts.map((p) => encodeURIComponent(p));
        parts.unshift('v0');
        parts.unshift(this.options.baseUrl);
        return parts.join('/');
    }
    /**
     * url: string, params?: Record<string, unknown>, query?: string, headers?: Headers, signal?: AbortSignal, analytics?: RequestAnalytics, anonymous?: boolean
     */
    get(url, options) {
        return this.send('GET', url, options);
    }
    /**
     * url: string, body: any, params?: Record<string, unknown>, signal?: AbortSignal, analytics?: RequestAnalytics, anonymous?: boolean
     */
    put(url, body, options) {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        return this.send('PUT', url, { body, ...options });
    }
    /**
     * url: string, body?: any, params?: Record<string, unknown>, signal?: AbortSignal, analytics?: RequestAnalytics, multipart?: boolean, anonymous?: boolean
     */
    post(url, options) {
        return this.send('POST', url, options);
    }
    /**
     * url: string, body: any, params?: Record<string, unknown>, signal?: AbortSignal, analytics?: RequestAnalytics, anonymous?: boolean
     */
    patch(url, body, options) {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        return this.send('PATCH', url, { body, ...options });
    }
    /**
     * url: string, signal?: AbortSignal, analytics?: RequestAnalytics, anonymous?: boolean
     */
    delete(url, options) {
        return this.send('DELETE', url, { ...options });
    }
    head(url, options) {
        return this.send('HEAD', url, { ...options });
    }
    /**
     * file: File, url: string, id?: string, progressCallback?: (progress: Progress) => void, signal?: AbortSignal
     */
    upload(file, url, options) {
        return this.request.upload({ file, url, ...options });
    }
    async send(method, url, options) {
        this.requestStart();
        url = appendParamsToUrl(url, options === null || options === void 0 ? void 0 : options.params, options === null || options === void 0 ? void 0 : options.query);
        try {
            return await this.request.send({ method, url, ...options });
        }
        finally {
            this.requestComplete();
        }
    }
    async oauth({ username, password, code, redirect_uri, provider_id }) {
        this.requestStart();
        try {
            if (password && username) {
                await this.authRequest.authWithPassword({ username, password });
            }
            else if (code) {
                await this.authRequest.authWithCode({ code, redirectUri: redirect_uri, providerId: provider_id });
            }
            return;
        }
        finally {
            this.requestComplete();
        }
    }
    requestStart() {
        if (this.start) {
            this.start();
        }
    }
    requestComplete() {
        if (this.end) {
            this.end();
        }
    }
    // TokenStorage Accessors
    // Session Token accessors
    set token(v) {
        if (this.storage) {
            this.storage.token = v;
        }
        else {
            this._token = v;
        }
    }
    get token() {
        if (this.storage) {
            return this.storage.token;
        }
        return this._token;
    }
    // Token Expiry Accessors
    set expiresAt(v) {
        if (this.storage) {
            this.storage.expiresAt = v;
        }
        else {
            this._expiresAt = v;
        }
    }
    get expiresAt() {
        if (this.storage) {
            return this.storage.expiresAt;
        }
        return this._expiresAt;
    }
    // Refresh token accessors
    async setRefreshToken(v) {
        if (this.storage) {
            await this.storage.setRefreshToken(v);
        }
        else {
            this._refreshToken = v;
        }
    }
    async getRefreshToken() {
        if (this.storage) {
            try {
                return await this.storage.getRefreshToken();
            }
            catch (e) {
                return null;
            }
        }
        return this._refreshToken;
    }
}
