import { addSeconds, formatRFC3339 } from 'date-fns';
import { ClientContentType } from './client-models';
import { HeaderKeys } from './client-types';
import { APIPath, QueryParams } from './constants';
import { getError } from './functions';
export var AuthType;
(function (AuthType) {
    AuthType["RefreshToken"] = "refresh_token";
    AuthType["AuthorizationCode"] = "authorization_code";
    AuthType["Password"] = "password";
})(AuthType || (AuthType = {}));
/**
 * Simple wrapper for Auth requests, responsible for
 * - Creating fetch
 * - Saving auth information into storage
 * - Removing expired information
 */
export class AuthRequestWrapper {
    constructor(options, headers, storage) {
        this.options = options;
        this.headers = headers;
        this.storage = storage;
    }
    async authWithPassword(params) {
        await this.request(AuthType.Password, params);
    }
    async authWithCode({ code, redirectUri, providerId }) {
        await this.request(AuthType.AuthorizationCode, { code, redirect_uri: redirectUri, provider_id: providerId });
    }
    authWithRefreshToken(refreshToken) {
        // Returns AuthToken
        return this.request(AuthType.RefreshToken, { refresh_token: refreshToken });
    }
    async request(grantType, params) {
        const body = this.getRequestBody({ grant_type: grantType, ...params });
        const headers = this.headers.base;
        headers.set(HeaderKeys.ContentType, ClientContentType.ApplicationXWwwFormUrlencoded);
        const response = await fetch(this.options.baseUrl + `/${APIPath.Oauth}`, {
            method: 'POST',
            headers,
            body,
        });
        if (response.ok) {
            const authResponse = await response.json();
            await this.updateStorage(authResponse);
            return authResponse.access_token;
        }
        // Auth Error
        this.storage.expiresAt = null;
        this.storage.token = null;
        await this.storage.setRefreshToken(null);
        throw await getError(response);
    }
    getRequestBody(params) {
        const urlSearchParams = this.urlSearchParams;
        for (const key of Object.keys(params)) {
            const value = params[key];
            if (value) {
                urlSearchParams.set(key, value);
            }
        }
        return urlSearchParams.toString();
    }
    get urlSearchParams() {
        const urlSearchParams = new URLSearchParams();
        if (this.options.appId) {
            urlSearchParams.set(QueryParams.ClientId, this.options.appId);
        }
        return urlSearchParams;
    }
    async updateStorage(resp) {
        this.storage.token = resp.access_token;
        // subtract a 5 second buffer from expiry for robustness
        const expiresAt = formatRFC3339(addSeconds(new Date(), resp.expires_in - 5));
        this.storage.expiresAt = expiresAt;
        await this.storage.setRefreshToken(resp.refresh_token);
    }
}
