import { AbortedError, getErrorType } from './functions';
/**
 * Uploader needs some work copy pasted from client
 * would be good to break up upload function
 */
export class Uploader {
    upload({ file, url, id, onProgress, signal, accessToken, appId }) {
        // TODO break this method up into smaller
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            if (signal) {
                signal.addEventListener('abort', () => {
                    console.log('Client.upload - signal abort received!');
                    xhr.abort();
                });
            }
            const formData = new FormData();
            const key = id == null ? 'file' : `file-${id}`;
            const blob = new Blob([file], { type: file.type });
            formData.append(key, blob, file.name);
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept-Language', 'source');
            if (accessToken != null) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
            }
            if (appId != null) {
                xhr.setRequestHeader('X-Unifii-App', appId);
            }
            let lastProgress;
            xhr.upload.onprogress = (e) => {
                lastProgress = {
                    done: e.loaded,
                    total: e.total,
                };
                if (onProgress) {
                    onProgress(lastProgress);
                }
            };
            xhr.upload.onabort = () => {
                console.log('Client.upload - xhr.upload.abort received! => Promise.reject!');
                reject(AbortedError);
            };
            xhr.onerror = (e) => {
                reject(e);
            };
            xhr.onloadend = (e) => {
                const responseId = this.getResponseId(xhr.response);
                if (responseId) {
                    lastProgress.id = responseId;
                    resolve(lastProgress);
                }
                else {
                    let error = e;
                    if (e.target && e.target instanceof XMLHttpRequest) {
                        error = this.getError(e.target);
                    }
                    reject(error);
                }
            };
            xhr.responseType = 'json';
            xhr.send(formData);
        });
    }
    getResponseId(response) {
        if (typeof response === 'string') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            response = JSON.parse(response);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (response === null || response === void 0 ? void 0 : response.id) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                return response.id;
            }
        }
        if (response != null && typeof response === 'object') {
            if (Array.isArray(response)) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                response = response[0];
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return response.id;
        }
        return undefined;
    }
    getError(request) {
        const { status } = request;
        const type = getErrorType(status);
        const code = `${status}`;
        return { type, code };
    }
}
