import { QueryCombinatorOperators, QueryIdentifierArgsOnlyOperators, QueryOperatorOperators } from './constants';
import { NodeType, QueryOperators, SortDirections } from './models';
/// Q represents a node in Query AST
/// and contains convenience functions for constructing queries
export class Q {
    constructor(type, op = null, ...args) {
        this.type = type;
        this.op = op;
        this.args = args;
    }
    static value(value) {
        const node = new Q(NodeType.Value);
        node.value = value;
        return node;
    }
    static identifier(identifier) {
        const node = new Q(NodeType.Identifier);
        node.value = identifier;
        return node;
    }
    static safe(value, op) {
        if (op === QueryOperators.In && !Array.isArray(value)) {
            return [value];
        }
        return value;
    }
    static fromAst(ast) {
        var _a, _b;
        if (!(ast === null || ast === void 0 ? void 0 : ast.op)) {
            return;
        }
        // UNIFII-6603 Normalize errata AstNode with type Operator and op 'or' | 'and' | 'not' to type Combinator
        if (ast.type === NodeType.Operator && QueryCombinatorOperators.includes(ast.op)) {
            ast.type = NodeType.Combinator;
        }
        // UNIFII-6611 Normalize errata AstNode with comparison operators to type Operator
        if (ast.type === NodeType.Value && QueryOperatorOperators.includes(ast.op)) {
            ast.type = NodeType.Operator;
        }
        // Resolve combinator node
        if (((_a = ast.args) === null || _a === void 0 ? void 0 : _a.length) && ast.type === NodeType.Combinator) {
            if (ast.op === QueryOperators.Not && ast.args.length !== 1) {
                return;
            }
            const qs = ast.args.map((a) => this.fromAst(a)).filter((q) => q != null);
            return new Q(NodeType.Combinator, ast.op, ...qs);
        }
        else if (((_b = ast.args) === null || _b === void 0 ? void 0 : _b.length) && ast.type === NodeType.Operator) {
            // Resolve operator node
            const identifierNodes = ast.args.filter((node) => node.type === NodeType.Identifier && node.value);
            const valueNodes = ast.args.filter((node) => node.type === NodeType.Value && node.value != null /* 0 and false are valid values */);
            const isIdentifierOnly = QueryIdentifierArgsOnlyOperators.includes(ast.op);
            // operator with 0...n identifiers as args
            if (ast.op === QueryOperators.Include) {
                return new Q(NodeType.Operator, ast.op, ...identifierNodes.map((a) => Q.identifier(a.value)));
            }
            // operator with only identifier args
            if (isIdentifierOnly && identifierNodes.length && !valueNodes.length) {
                return new Q(NodeType.Operator, ast.op, Q.identifier(identifierNodes[0].value));
            }
            // operator with identifier & value args
            if (!isIdentifierOnly && identifierNodes.length && valueNodes.length) {
                return new Q(NodeType.Operator, ast.op, Q.identifier(identifierNodes[0].value), Q.value(Q.safe(valueNodes[0].value, ast.op)));
            }
        }
        return undefined;
    }
    static eq(identifier, value) {
        return Q.cmp(QueryOperators.Equal, identifier, value);
    }
    static ne(identifier, value) {
        return Q.cmp(QueryOperators.NotEqual, identifier, value);
    }
    static lt(identifier, value) {
        return Q.cmp(QueryOperators.LowerThan, identifier, value);
    }
    static le(identifier, value) {
        return Q.cmp(QueryOperators.LowerEqual, identifier, value);
    }
    static gt(identifier, value) {
        return Q.cmp(QueryOperators.GreaterThan, identifier, value);
    }
    static ge(identifier, value) {
        return Q.cmp(QueryOperators.GreaterEqual, identifier, value);
    }
    static in(identifier, value) {
        return Q.cmp(QueryOperators.In, identifier, value);
    }
    static contains(identifier, value) {
        return Q.cmp(QueryOperators.Contains, identifier, value);
    }
    static descs(identifier, value) {
        return Q.cmp(QueryOperators.Descendants, identifier, value);
    }
    static not(q) {
        return new Q(NodeType.Combinator, QueryOperators.Not, q);
    }
    static and(...qs) {
        if (qs.length === 1) {
            return qs[0];
        }
        return new Q(NodeType.Combinator, QueryOperators.And, ...qs);
    }
    static or(...qs) {
        if (qs.length === 1) {
            return qs[0];
        }
        return new Q(NodeType.Combinator, QueryOperators.Or, ...qs);
    }
    stringify() {
        if (this.type === NodeType.Expression) {
            throw new Error('Query cannot stringify NodeType.Expression, expression must be evalutated and converted to NodeType.Value');
        }
        if (this.type === NodeType.Identifier) {
            return this.value;
        }
        if (this.type === NodeType.Value) {
            return stringifyValue(this.value);
        }
        if (this.op === QueryOperators.Limit) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return `limit(${this.args[0].value},${this.args[1].value})`;
        }
        if (this.op === QueryOperators.Include) {
            return `include(${this.args.filter((a) => a.type === NodeType.Identifier).map((a) => a.value).join(',')})`;
        }
        return `${this.op}(${this.args.map((q) => q.stringify()).join(',')})`;
    }
    static cmp(op, identifier, value) {
        return new Q(NodeType.Operator, op, Q.identifier(identifier), Q.value(Q.safe(value, op)));
    }
}
export class Query extends Q {
    constructor() {
        super(NodeType.Combinator, QueryOperators.And);
    }
    // Equal
    eq(identifier, value) {
        this.args.push(Q.eq(identifier, value));
        return this;
    }
    // Not equal
    ne(identifier, value) {
        this.args.push(Q.ne(identifier, value));
        return this;
    }
    // Less than
    lt(identifier, value) {
        this.args.push(Q.lt(identifier, value));
        return this;
    }
    // Less than or equal
    le(identifier, value) {
        this.args.push(Q.le(identifier, value));
        return this;
    }
    // Greater than
    gt(identifier, value) {
        this.args.push(Q.gt(identifier, value));
        return this;
    }
    // Greater than or equal
    ge(identifier, value) {
        this.args.push(Q.ge(identifier, value));
        return this;
    }
    // Equal to any of these (is in set?)
    in(identifier, value) {
        this.args.push(Q.in(identifier, value));
        return this;
    }
    // Array contains this item
    contains(identifier, value) {
        this.args.push(Q.contains(identifier, value));
        return this;
    }
    // Same or descendant unit in the hierarchy
    descs(identifier, value) {
        this.args.push(Q.descs(identifier, value));
        return this;
    }
    // Negate
    not(q) {
        this.args.push(Q.not(q));
        return this;
    }
    // All input queries
    and(...qs) {
        this.args.push(Q.and(...qs));
        return this;
    }
    // Any of the input queries
    or(...qs) {
        this.args.push(Q.or(...qs));
        return this;
    }
    // Quert 'term'
    q(term) {
        this.args.push(new Q(NodeType.Operator, QueryOperators.Search, Q.value(term)));
        return this;
    }
    limit(count, start = 0) {
        if (count == null) {
            return this;
        }
        const index = this.args.findIndex((q) => q.op === QueryOperators.Limit);
        if (index >= 0) {
            this.args.splice(index, 1);
        }
        this.args.push(new Q(NodeType.Operator, QueryOperators.Limit, Q.value(count), Q.value(start)));
        return this;
    }
    sort(identifier, direction = SortDirections.Ascending) {
        const index = this.args.findIndex((q) => q.op === QueryOperators.Sort);
        if (index >= 0) {
            this.args.splice(index, 1);
        }
        const prefix = direction === SortDirections.Descending ? '-' : '+';
        this.args.push(new Q(NodeType.Operator, QueryOperators.Sort, Q.identifier(prefix + identifier)));
        return this;
    }
    include(identifiers) {
        const index = this.args.findIndex((q) => q.op === QueryOperators.Include);
        if (index >= 0) {
            this.args.splice(index, 1);
        }
        this.args.push(new Q(NodeType.Operator, QueryOperators.Include, ...identifiers.map((i) => Q.identifier(i))));
        return this;
    }
    fromAst(ast) {
        const q = Q.fromAst(ast);
        if (q != null) {
            this.args.push(q);
        }
        return this;
    }
    stringify() {
        return this.args.map((q) => q.stringify()).join('&');
    }
}
const stringifyValue = (value) => {
    if (Array.isArray(value)) {
        return `(${value.map(stringifyValue).join(',')})`;
    }
    if (typeof value === 'number' && isFinite(value)) {
        return `number:${value}`;
    }
    if (typeof value === 'boolean') {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `bool:${value}`;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return encodeURIComponent(value);
};
