import { formatRFC3339 } from 'date-fns';
import { ErrorType } from './client-models';
import { AbortedError, checkResponse, getBody } from './functions';
import { LocalStorageLockManager } from './lock-manager';
import { Uploader } from './uploader';
const MissingAccessTokenError = {
    type: ErrorType.Unauthorized,
    message: 'Access token is missing',
};
/**
 * RequestManager handles all SDK requests, responsible for:
 *  - Queuing all requests behind a single promise which gets access from storage or via http
 *  - Retrying unauthorized requests when a there is a valid refresh token
 *  - Attaching external interceptor
 */
export class RequestManager {
    constructor(options, headers, storage, authRequest, interceptor) {
        this.options = options;
        this.headers = headers;
        this.storage = storage;
        this.authRequest = authRequest;
        this.interceptor = interceptor;
        this.lockManager = new LocalStorageLockManager();
    }
    send(options, retryUnauthorized = true) {
        let pendingRequest = this.sendRequest(options);
        if (options.signal) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            pendingRequest = this.mapAbortedErrorType(pendingRequest);
        }
        if (retryUnauthorized) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            pendingRequest = this.interceptUnauthorized(pendingRequest, options);
        }
        if (this.interceptor) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            return this.interceptor.intercept(pendingRequest);
        }
        return pendingRequest;
    }
    async upload({ file, url, id, onProgress, signal }, retryUnauthorized = true) {
        var _a;
        // TODO replace with throwIfAborted()
        if (signal === null || signal === void 0 ? void 0 : signal.aborted) {
            throw AbortedError;
        }
        const accessToken = await this.getAccessToken();
        const appId = (_a = this.headers.options.appId) !== null && _a !== void 0 ? _a : undefined;
        const uploader = new Uploader();
        let upload = uploader.upload({ file, url, id, onProgress, signal, accessToken, appId });
        if (retryUnauthorized) {
            upload = this.retryUploadInterceptor(upload, { file, url, id, onProgress, signal });
        }
        if (this.interceptor) {
            return this.interceptor.intercept(upload);
        }
        return upload;
    }
    async sendRequest({ method, url, body, signal, multipart, headers, anonymous, analytics }) {
        // TODO replace with throwIfAborted();
        if (signal === null || signal === void 0 ? void 0 : signal.aborted) {
            throw AbortedError;
        }
        const token = await this.getAccessToken();
        if (token == null && anonymous !== true && this.options.apiSecret == null) {
            throw MissingAccessTokenError;
        }
        if (body != null && multipart == null) {
            body = JSON.stringify(body);
        }
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        const signedHeaders = await this.headers.getSigned({ method, url, headers, body, contentType: undefined, multipart, accessToken: token, analytics });
        const resp = await fetch(url, {
            method,
            headers: signedHeaders,
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
            body,
            signal,
        });
        await checkResponse(resp, signal);
        if (method === 'HEAD') {
            return resp.headers;
        }
        return await getBody(resp, signal);
    }
    interceptUnauthorized(pendingRequest, options) {
        return pendingRequest.catch((err) => {
            if (err.type !== ErrorType.Unauthorized) {
                return pendingRequest;
            }
            // try to get token and retry request
            return this.getAccessToken(true)
                .catch(() => null)
                .then((token) => {
                if (token != null || options.anonymous) {
                    return this.send(options, false);
                }
                return pendingRequest;
            });
        });
    }
    mapAbortedErrorType(pendingRequest) {
        return pendingRequest.catch((err) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (err.code === ErrorType.AbortError) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                err.type = ErrorType.AbortError;
            }
            return pendingRequest;
        });
    }
    retryUploadInterceptor(upload, args) {
        return upload.catch((err) => {
            if (err.type !== ErrorType.Unauthorized) {
                return upload;
            }
            // try to get token and retry upload
            return this.getAccessToken(true)
                .catch(() => null)
                .then((token) => {
                if (token != null) {
                    return this.upload(args, false);
                }
                return upload;
            });
        });
    }
    getAccessToken(force = false) {
        if (this.pendingRefresh != null) {
            return this.pendingRefresh;
        }
        const token = this.storage.token;
        if (token == null) {
            return Promise.resolve(undefined);
        }
        if (!this.hasAccessTokenExpired() && !force) {
            return Promise.resolve(token);
        }
        this.pendingRefresh = this.refreshAccessToken(token).finally(() => { this.pendingRefresh = undefined; });
        return this.pendingRefresh;
    }
    async refreshAccessToken(prevToken) {
        let result;
        await this.lockManager.request('UnifiiRefreshToken', async () => {
            const refreshToken = await this.storage.getRefreshToken();
            if (refreshToken == null) {
                return;
            }
            const currentToken = this.storage.token;
            if (prevToken !== currentToken) {
                result = currentToken !== null && currentToken !== void 0 ? currentToken : undefined;
                return;
            }
            try {
                result = await this.authRequest.authWithRefreshToken(refreshToken);
            }
            catch (e) {
                // fail silenty to token is undefined
            }
        });
        return result;
    }
    hasAccessTokenExpired() {
        const expiresAt = this.storage.expiresAt;
        if (expiresAt == null) {
            return true;
        }
        // now is greater than expiry
        return expiresAt < formatRFC3339(new Date());
    }
}
