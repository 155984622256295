import { ClientContentType, ErrorType } from '../client-models';
import { HeaderKeys } from '../client-types';
import { QueryParams } from '../constants';
import { stringifyQuery } from './query-functions';
export const AbortedError = {
    // MDN attributes
    code: ErrorType.AbortError,
    message: 'The user aborted a request.',
    name: 'AbortError',
    // Unifii attributes
    type: ErrorType.AbortError,
};
export const getBody = async (response, signal) => {
    if (!(response === null || response === void 0 ? void 0 : response.headers)) {
        return;
    }
    const contentType = response.headers.get(HeaderKeys.ContentType);
    if (!contentType) {
        return;
    }
    // TODO replace with throwIfAborted()
    if (signal === null || signal === void 0 ? void 0 : signal.aborted) {
        throw AbortedError;
    }
    if (contentType.includes(ClientContentType.ApplicationJson)) {
        // There isn't a better way to detect empty body yet
        // https://github.com/angular/angular/issues/6735
        // Try this out https://mcculloughwebservices.com/2016/09/23/handling-a-null-response-from-an-api/
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return await response.json();
        }
        catch (error) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (error.code === ErrorType.AbortError) {
                console.log('Response.json() - aborted');
                throw error;
            }
            console.warn('Response.json() threw on empty body');
            return null;
        }
    }
    if (contentType.includes(ClientContentType.ApplicationPdf)) {
        return response.blob();
    }
    return response.text();
};
export const checkResponse = async (response, signal) => {
    if (response.ok) {
        return response;
    }
    throw await getError(response, signal);
};
export const getError = async (response, signal) => {
    const { status } = response;
    const type = getErrorType(status);
    const code = `${status}`;
    const body = {};
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data = await getBody(response, signal);
        if (data != null) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
            body.data = data;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            body.message = data.message;
        }
        // eslint-disable-next-line no-empty
    }
    catch (e) { }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return { type, code, ...body };
};
export const getErrorType = (status) => {
    if (status >= 500) {
        return ErrorType.Server;
    }
    switch (status) {
        case 400: return ErrorType.Validation;
        case 401: return ErrorType.Unauthorized;
        case 403: return ErrorType.Forbidden;
        case 404: return ErrorType.NotFound;
        case 409: return ErrorType.Conflict;
        default: return ErrorType.Unknown;
    }
};
export const appendParamsToUrl = (url, params, query) => {
    if (params != null && Object.keys(params).length > 0) {
        const searchParams = new URLSearchParams();
        for (const key of Object.keys(params)) {
            if (params[key] != null) {
                searchParams.set(key, params[key]);
            }
        }
        return `${url}?${searchParams.toString()}`;
    }
    if (query) {
        return `${url}?${query}`;
    }
    return url;
};
export const mergeHeaders = (...headers) => {
    const result = {};
    for (const source of headers) {
        if (source == null || typeof source !== 'object') {
            continue;
        }
        const sourceHeaders = new Headers(source);
        sourceHeaders.forEach((value, key) => {
            if (value === 'undefined') {
                delete result.key;
            }
            else {
                result[key] = value;
            }
        });
    }
    return new Headers(result);
};
/**
 * Return a valorized params object without empty entries, doesn't modify the input
 *
 * @param params the params
 * @returns a purged and valorized params object
 */
export const purgeParams = (params) => {
    const output = { ...params };
    for (const key of Object.keys(output)) {
        if (output[key] == null) {
            delete output.key;
        }
    }
    return output;
};
/**
 * Return a valorized params object result of the merged inputs, doesn't modify the inputs
 *
 * @param params array of params to merge into a new params object
 * @returns a purged and merged params object
 */
export const mergeParams = (...params) => {
    const result = {};
    for (const entry of params.map(purgeParams)) {
        for (const key of Object.keys(entry)) {
            result[key] = entry[key];
        }
    }
    return result;
};
/**
 * Return a request options object with amended params entries in case they are missing from the input options
 * Doesn't modify the inputs
 *
 * @param params params entries to amend in the options
 * @param options target to amend the params into
 * @returns a new options object with amended params
 */
export const amendOptionsParams = (params, options) => {
    const result = { ...options };
    result.params = mergeParams(params, result.params);
    return result;
};
export const composeDownloadUrl = (url, format, query) => {
    let result = `${url}?${QueryParams.Format}=${format}`;
    const stringifiedQuery = stringifyQuery(query);
    if (stringifiedQuery) {
        result += `&${stringifiedQuery}`;
    }
    return result;
};
/**
 * Extrac the etag value from its double quote enclosure
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/ETag
 * @param headers
 */
export const getEtag = (headers) => {
    const enclosedEtag = headers.get(HeaderKeys.Etag);
    if (!enclosedEtag) {
        return;
    }
    let etag = enclosedEtag.replace(/"/g, '');
    // regex to test that the string start with (case-sensitive) W/
    const weakRegex = /^(W\/)/gm;
    const weak = weakRegex.test(etag);
    if (weak) {
        etag = etag.replace(weakRegex, '');
    }
    if (!etag.length) {
        return;
    }
    return { etag, weak };
};
/**
 * Prepare a W3C compliant If-Match header value
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/If-Match
 * @param revisions
 */
export const buildIfMatchHeaderValue = (revisions) => {
    if (!(revisions === null || revisions === void 0 ? void 0 : revisions.length)) {
        return;
    }
    return revisions
        .filter((r) => r != null)
        .map((r) => /^["](.*["])?$/gm.test(r) ? r : `"${r}"`)
        .join(', ');
};
