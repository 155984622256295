import { ClientContentType } from './client-models';
import { HeaderKeys } from './client-types';
import { hashBody, hmac } from './functions';
/**
 * ClientHeaders is responsible for handling all logic
 * related to SDK request headers
 * methods:
 * - get > returns headers for all requests
 * options:
 * - skip unifii headers set via excludeUnifiiRequestHeaders, usefull when client is extended and does not require any unifii headers
 */
export class ClientHeaders {
    constructor(options) {
        this.options = options;
        this._deviceId = null;
    }
    getSigned({ method, url, headers, body, contentType, multipart, accessToken, analytics }) {
        const baseHeaders = this.base;
        if (accessToken) {
            baseHeaders.set(HeaderKeys.Authorization, 'Bearer ' + accessToken);
        }
        const mergedHeaders = this.merge(baseHeaders, headers);
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment*/
        return this.sign({ headers: mergedHeaders, method, url, body, contentType, multipart, analytics });
    }
    set deviceId(v) {
        this._deviceId = v;
    }
    get deviceId() {
        return this._deviceId;
    }
    get base() {
        const headers = new Headers({ [HeaderKeys.AcceptLanguage]: 'source' });
        if (this.options.excludeUnifiiRequestHeaders) {
            return headers;
        }
        if (this.options.appId != null) {
            headers.set(HeaderKeys.XUnifiiApp, [this.options.appId, this.options.appVersion, this.options.platform].join('|'));
        }
        if (this.deviceId != null) {
            headers.set(HeaderKeys.XUnifiiDevice, this.deviceId);
        }
        return headers;
    }
    merge(headers, extraHeaders) {
        if (extraHeaders != null) {
            (extraHeaders).forEach((value, key) => {
                if (value && key) {
                    headers.set(key, value);
                }
            });
        }
        return headers;
    }
    async sign({ headers, method, url, body, contentType, multipart, analytics }) {
        var _a;
        if (!contentType) {
            contentType = ClientContentType.ApplicationJson;
        }
        if (!multipart) {
            headers.set(HeaderKeys.ContentType, contentType);
        }
        if (analytics) {
            const analyticsCSV = Object.keys(analytics)
                .filter((k) => analytics[k] != null)
                .map((k) => `${k}=${analytics[k]}`)
                .join(';');
            if (analyticsCSV.trim().length > 0) {
                headers.set(HeaderKeys.XUnifiiAnalytics, analyticsCSV);
            }
        }
        // Authorization in Header, no need for sign
        if (headers.get(HeaderKeys.Authorization) || this.signatureInfo == null) {
            return headers;
        }
        const stamp = new Date().toISOString();
        headers.set(HeaderKeys.XUnifiiDate, stamp);
        const parsedURL = new URL(url);
        const acceptLanguage = (_a = headers.get(HeaderKeys.AcceptLanguage)) !== null && _a !== void 0 ? _a : '';
        const sts = [
            method.toUpperCase(),
            parsedURL.pathname,
            parsedURL.search ? parsedURL.search.substring(1) : '',
            `host:${parsedURL.hostname}`,
            `content-type:${contentType}`,
            `accept-language:${acceptLanguage}`,
            stamp,
            await hashBody(body || ''),
        ].join('\n');
        const signature = await hmac(sts, this.signatureInfo.secret);
        headers.set(HeaderKeys.Authorization, `UNIFII ${this.signatureInfo.key}:${signature}`);
        return headers;
    }
    get signatureInfo() {
        if (this.options.apiKey && this.options.apiSecret) {
            return {
                key: this.options.apiKey,
                secret: this.options.apiSecret,
            };
        }
        if (this.options.appId && this.options.appSecret) {
            return {
                key: this.options.appId,
                secret: this.options.appSecret,
            };
        }
        return undefined;
    }
}
