import { HeaderKeys } from '../client-types';
import { APIPath, QueryParams } from '../constants';
import { mergeHeaders, stringifyQuery } from '../functions';
/** InjectionToken like */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class, @typescript-eslint/no-unsafe-declaration-merging
export class PublishedContent {
}
export class ContentClient {
    constructor(client, tenantClient, options) {
        this.client = client;
        this.tenantClient = tenantClient;
        this.options = options;
        if (this.options.version) {
            this.contentVersionHeader = new Headers({ [HeaderKeys.XUnifiiContentVersion]: this.options.version });
        }
    }
    // PublishedContent implementation START
    getView(identifier, options) {
        return this.client.get(this.url(APIPath.Views, identifier), this.mergeOptions(options));
    }
    getViewDefinition(identifier, options) {
        return this.client.get(this.url(APIPath.Views, identifier, APIPath.ViewsDefinition), this.mergeOptions(options));
    }
    getPage(identifier, options) {
        return this.client.get(this.url(APIPath.Pages, identifier), this.mergeOptions(options));
    }
    getCollectionDefinition(identifier, options) {
        return this.client.get(this.url(APIPath.Collections, identifier, APIPath.CollectionsDefinition), this.mergeOptions(options));
    }
    getCollections(options) {
        return this.client.get(this.url(APIPath.Collections), this.mergeOptions(options));
    }
    queryCollection(identifier, query, options) {
        return this.client.get(this.url(APIPath.Collections, identifier), this.mergeOptions(options, query));
    }
    getCollectionItem(identifier, id, options) {
        return this.client.get(this.url(APIPath.Collections, identifier, id), this.mergeOptions(options));
    }
    getBucket(identifier, options) {
        return this.client.get(this.url(APIPath.Buckets, identifier), this.mergeOptions(options));
    }
    queryForms(query, options) {
        return this.client.get(this.url(APIPath.Forms), this.mergeOptions(options, query));
    }
    getForm(identifier, version, options) {
        let parts = [identifier];
        // only add versions for prod
        if (!this.options.preview && version != null) {
            parts = parts.concat(APIPath.FormsVersions, `${version}`);
        }
        return this.client.get(this.url(APIPath.Forms, ...parts), this.mergeOptions(options));
    }
    queryTables(query, options) {
        return this.client.get(this.url(APIPath.Tables), this.mergeOptions(options, query));
    }
    getTable(identifier, options) {
        return this.client.get(this.url(APIPath.Tables, identifier), this.mergeOptions(options));
    }
    getStructure(options) {
        return this.client.get(this.url(APIPath.Structure), this.mergeOptions(options));
    }
    getIdentifiers(options) {
        return this.client.get(this.url(APIPath.Identifiers), this.mergeOptions(options));
    }
    async getAssetUrl(id) {
        const { url } = await this.tenantClient.getAsset(id);
        return url;
    }
    queryPages(query, options) {
        return this.client.get(this.url(APIPath.Pages), this.mergeOptions(options, query));
    }
    // PublishedContent implementation END
    createVersionedContent(version) {
        return new ContentClient(this.client, this.tenantClient, {
            preview: this.options.preview,
            projectId: this.options.projectId,
            version,
        });
    }
    queryViews(query, options) {
        return this.client.get(this.url(APIPath.Views), this.mergeOptions(options, query));
    }
    async getFormVersions(identifier, options) {
        if (this.options.preview) {
            // Preview do not support versions history
            return [await this.getForm(identifier, undefined, options)];
        }
        return this.client.get(this.url(APIPath.Forms, identifier, APIPath.FormsVersions), this.mergeOptions(options));
    }
    getVersion(version, options) {
        return this.client.get(this.versionsUrl(version), this.mergeOptions(options));
    }
    getLatestVersion(artifact, options) {
        const params = { preview: this.options.preview, artifact };
        return this.client.get(this.versionsUrl(APIPath.Latest), this.mergeOptions(options, undefined, params));
    }
    getArtifactUrl(version, artifact) {
        return this.versionsUrl(version, APIPath.Artifacts, artifact);
    }
    getArtifactPatchUrl(version, artifact, fromVersion) {
        return `${this.versionsUrl(version, APIPath.Artifacts, artifact, APIPath.Diff)}?${QueryParams.Form}=${encodeURI(fromVersion)}`;
    }
    buildImageUrl(imageProfile, options) {
        if (imageProfile.url == null) {
            return;
        }
        const url = new URL(imageProfile.url);
        // set crop
        if (imageProfile.crop) {
            url.searchParams.set(QueryParams.ImageCrop, `(${imageProfile.crop.x}, ${imageProfile.crop.y}, ${imageProfile.crop.x + imageProfile.crop.width}, ${imageProfile.crop.y + imageProfile.crop.height})`);
        }
        if (options === null || options === void 0 ? void 0 : options.width) {
            const bracketedWidth = Math.ceil(options.width / 256) * 256;
            url.searchParams.set(QueryParams.ImageWidth, bracketedWidth.toString());
        }
        return url.toString();
    }
    mergeOptions(options, query, params) {
        return {
            ...options,
            headers: mergeHeaders(options === null || options === void 0 ? void 0 : options.headers, this.contentVersionHeader),
            query: stringifyQuery(query),
            params: { ...options === null || options === void 0 ? void 0 : options.params, ...params },
        };
    }
    versionsUrl(...extra) {
        return this.client.buildUrl(APIPath.Projects, this.options.projectId, APIPath.Versions, ...extra);
    }
    url(...extra) {
        const projectParts = [APIPath.Projects, this.options.projectId];
        if (this.options.preview) {
            projectParts.push(APIPath.Preview);
        }
        return this.client.buildUrl(...projectParts, ...extra);
    }
}
