import { APIPath } from '../constants';
/** Client for v0/projects/:id/external-data-sources/:externalDataSourceId APIs */
export class ExternalDataSourcesClient {
    constructor(client, projectOptions) {
        this.client = client;
        this.projectOptions = projectOptions;
    }
    query(externalDataSourceId, options) {
        return this.client.get(this.url(externalDataSourceId), options);
    }
    get(externalDataSourceId, itemId, options) {
        return this.client.get(this.url(externalDataSourceId, itemId), options);
    }
    url(externalDataSourceId, ...args) {
        const parts = [
            APIPath.Projects,
            this.projectOptions.projectId,
            APIPath.ExternalDataSources,
            externalDataSourceId,
            ...args,
        ];
        return this.client.buildUrl(...parts);
    }
}
