import { APIPath } from '../constants';
/** Client for v0/me APIs */
export class MeClient {
    constructor(client) {
        this.client = client;
    }
    getMe() {
        return this.client.get(this.url());
    }
    updatePassword(passwordDetails) {
        /** Patch method is being used exclusively for updating password details */
        return this.client.patch(this.url(), passwordDetails);
    }
    getPermissions() {
        return this.client.get(this.url(APIPath.MePermissions));
    }
    update(user, params) {
        return this.client.put(this.url(), user, { params: { ...params }, anonymous: true });
    }
    url(...extra) {
        return this.client.buildUrl(APIPath.Me, ...extra);
    }
}
