import { Formats } from '../client-models';
import { HeaderKeys } from '../client-types';
import { APIPath } from '../constants';
import { buildIfMatchHeaderValue, composeDownloadUrl, getEtag, stringifyQuery } from '../functions';
/** Client for v0/projects/:id/form-data APIs */
export class FormDataClient {
    constructor(client, options) {
        this.client = client;
        this.options = options;
    }
    query(query, options) {
        return this.client.get(this.url(), { ...options, query: stringifyQuery(query) });
    }
    count(query, options) {
        return this.client.get(this.url(APIPath.FormDataCount), { ...options, query: stringifyQuery(query) });
    }
    async get(id, options) {
        var _a;
        const formData = await this.client.get(this.url(id), options);
        formData._bucket = (_a = formData._bucket) !== null && _a !== void 0 ? _a : this.options.bucket; // Amend legacy FormData missing _bucket
        return formData;
    }
    async getRevision(id, options) {
        var _a;
        const headers = await this.client.head(this.url(id), options);
        return (_a = getEtag(headers)) === null || _a === void 0 ? void 0 : _a.etag;
    }
    save(data, options) {
        if (!data.id) {
            // Save
            return this.client.post(this.url(), { body: data });
        }
        // Update
        const ifMatchValue = buildIfMatchHeaderValue([options === null || options === void 0 ? void 0 : options.revision]);
        const headers = ifMatchValue ? new Headers({ [HeaderKeys.IfMatch]: ifMatchValue }) : undefined;
        return this.client.put(this.url(data.id), data, { headers });
    }
    uploadAttachment(file, options) {
        return this.client.upload(file, this.fileUrl(), options);
    }
    getRevisions(id, options) {
        return this.client.get(this.url(id, APIPath.FormDataRevisions), options);
    }
    getRevisionData(id, rev, options) {
        return this.client.get(this.url(id, APIPath.FormDataRevisions, rev), options);
    }
    async getAttachmentUrl(id, params = {}) {
        const url = this.fileUrl(id);
        const { token } = await this.client.getDownloadToken(url);
        const searchParams = new URLSearchParams(Object.assign(params, { _dlt: token }));
        return `${url}?${searchParams.toString()}`;
    }
    getDownloadUrl(query, format = Formats.Csv) {
        return composeDownloadUrl(this.url(), format, query);
    }
    /**
     * @deprecated use getRevisions
     * @since 23/03/2022
     */
    getAllRevisionData(id) {
        return this.getRevisions(id);
    }
    url(...extra) {
        return this.client.buildUrl(...this.baseUrlParts, this.options.bucket, ...extra);
    }
    fileUrl(...extra) {
        return this.client.buildUrl(...this.baseUrlParts, APIPath.FormDataFiles, ...extra);
    }
    get baseUrlParts() {
        const projectParts = [APIPath.Projects, this.options.projectId];
        if (this.options.preview) {
            projectParts.push(APIPath.Preview);
        }
        return [...projectParts, APIPath.FormData];
    }
}
